import React, { useState } from 'react';
import { Layout, Menu, Card, Statistic, Select, Typography, Col, Row } from 'antd';
import { LineChartOutlined, DashboardOutlined, TeamOutlined } from '@ant-design/icons';
import './HQDashboard.css';

const { Sider, Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

const HQDashboard = () => {
    const staticData = {
        Location1: {
            totalAverageWaitTime: 42.3,
            totalCarsServed: 350,
            highLevelInsights: [
                { insight: 'Location 1 is experiencing lower traffic during lunch hours. Consider adding promotions to increase visits.' },
                { insight: 'High wait times in the evening. Additional staffing might be required.' },
            ]
        },
        Location2: {
            totalAverageWaitTime: 37.8,
            totalCarsServed: 290,
            highLevelInsights: [
                { insight: 'Location 2 has lower wait times in the early afternoon. Explore cross-promotions during this period.' },
                { insight: 'High traffic during morning rush hours. Consider optimizing service speed to handle demand.' },
            ]
        }
    };

    const [selectedLocation, setSelectedLocation] = useState("Location1");
    const locationData = staticData[selectedLocation];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <div className="logo">HQ Dashboard</div>
            <Sider className="sidebar" width={260}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['3']}
                    items={[
                        { key: '1', label: 'Operator Dashboard', icon: <DashboardOutlined /> },
                        { key: '2', label: 'Manager Dashboard', icon: <TeamOutlined /> },
                        { key: '3', label: 'HQ Dashboard', icon: <LineChartOutlined /> },
                    ]}
                />
            </Sider>

            <Content className="content">

                {/* Location Selector */}
                <Card className="location-selector-card">
                    <Title level={5} style={{ color: '#1890ff', marginBottom: '8px' }}>Select Location</Title>
                    <Select
                        defaultValue={selectedLocation}
                        style={{ width: '100%' }}
                        onChange={value => setSelectedLocation(value)}
                        dropdownClassName="location-dropdown"
                    >
                        <Option value="Location1">Location 1</Option>
                        <Option value="Location2">Location 2</Option>
                    </Select>
                </Card>

                <div className="dashboard-container">
                    {/* Total Average Wait Time Card */}
                    <Card className="dashboard-card">
                        <Statistic
                            title="Overall Average Wait Time"
                            value={locationData.totalAverageWaitTime}
                            suffix="s"
                        />
                        <p className="card-description">Average wait time across all stations at {selectedLocation}</p>
                    </Card>

                    {/* Total Cars Served Card */}
                    <Card className="dashboard-card">
                        <Statistic
                            title="Total Cars Served"
                            value={locationData.totalCarsServed}
                        />
                        <p className="card-description">Total number of cars served at {selectedLocation}</p>
                    </Card>
                </div>

                {/* High-Level Insights Section */}
                <div className="insights-section">
                    <h3>High-Level Insights for {selectedLocation}</h3>
                    <Row gutter={[16, 16]}>
                        {locationData.highLevelInsights.map((item, index) => (
                            <Col key={index} xs={24} sm={24} md={12} lg={8}>
                                <Card className="insight-card">
                                    <Text>{item.insight}</Text>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Content>
        </Layout>
    );
};

export default HQDashboard;
