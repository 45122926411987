import React, { useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import {
  DashboardOutlined,
  TeamOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import OperatorDashboard from './pages/OperatorDashboard';
import ManagerDashboard from './pages/ManagerDashboard';
import HQDashboard from './pages/HQDashboard';
import './App.css';

const { Sider, Content } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        {/* Left Navigation Sider */}
        <Sider
          width={260}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          className="main-menu"
          breakpoint="lg"
          theme="dark"
          style={{
            height: '100vh',
            position: 'fixed',
            left: 0,
            overflow: 'auto',
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <div className="logo">
            <Button
              type="primary"
              onClick={toggleCollapsed}
              style={{ marginBottom: 16 }}
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            />
            {!collapsed && <span className="logo-text">Drive-Thru System</span>}
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item key="1" icon={<DashboardOutlined />} className={collapsed ? 'collapsed-icon-only' : ''}>
              <Link to="/operator">{!collapsed && 'Operator'}</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<TeamOutlined />} className={collapsed ? 'collapsed-icon-only' : ''}>
              <Link to="/manager">{!collapsed && 'Manager'}</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<LineChartOutlined />} className={collapsed ? 'collapsed-icon-only' : ''}>
              <Link to="/hq">{!collapsed && 'HQ'}</Link>
            </Menu.Item>
          </Menu>
        </Sider>

        {/* Main Content Area */}
        <Layout style={{ marginLeft: collapsed ? 80 : 260 }}>
          <Content
            style={{
              margin: '24px 16px 0',
              padding: 24,
              background: '#f0f2f5',
              minHeight: '100vh',
              transition: 'all 0.3s ease-in-out',
            }}
          >
            <Routes>
              <Route path="/operator" element={<OperatorDashboard />} />
              <Route path="/manager" element={<ManagerDashboard />} />
              <Route path="/hq" element={<HQDashboard />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
