import React, { useEffect, useState } from 'react';
import { Layout, Menu, Card, Statistic, Divider, Table } from 'antd';
import { CarOutlined, ClockCircleOutlined, DashboardOutlined, TeamOutlined, LineChartOutlined } from '@ant-design/icons';
import io from 'socket.io-client';
import './OperatorDashboard.css';

const { Sider, Content } = Layout;

const OperatorDashboard = () => {
    const [carQueue, setCarQueue] = useState(0);
    const [stationData, setStationData] = useState({
        order: [],
        payment: [],
        pickup: [],
    });
    const [insights, setInsights] = useState([]);

    useEffect(() => {
        const socket = io('http://44.210.104.161:80', { transports: ['websocket', 'polling'] });

        socket.on('connect', () => {
            console.log('Connected to WebSocket');
        });

        socket.on('car_queue', (data) => {
            setCarQueue(data.length);
        });

        socket.on('station_updates', (stations) => {
            const updatedStations = {};
            Object.keys(stations).forEach(station => {
                updatedStations[station] = stations[station].map(car => ({
                    ...car,
                    timeSpent: car.time_spent !== undefined ? car.time_spent : 0  // Ensure timeSpent is defined
                }));
            });
            setStationData(updatedStations);
        });

        socket.on('insights_update', (data) => {
            setInsights(data);
        });

        socket.on('disconnect', () => {
            console.log('WebSocket disconnected');
        });

        return () => socket.disconnect();
    }, []);

    const insightsColumns = [
        {
            title: 'Station',
            dataIndex: 'station',
            key: 'station',
        },
        {
            title: 'Insight',
            dataIndex: 'suggested_action',
            key: 'suggested_action',
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <div className="logo">Operator Dashboard</div>
            <Sider className="sidebar" width={260}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={[
                        { key: '1', label: 'Operator Dashboard', icon: <DashboardOutlined /> },
                        { key: '2', label: 'Manager Dashboard', icon: <TeamOutlined /> },
                        { key: '3', label: 'HQ Dashboard', icon: <LineChartOutlined /> },
                    ]}
                />
            </Sider>
            
            <Content className="content">
                
                <div className="dashboard-container">

                    {/* Order Station Card */}
                    <Card className="dashboard-card">
                        <Statistic
                            title="Cars at Order Station"
                            value={stationData.order.length}
                            prefix={<ClockCircleOutlined />}
                        />
                        <Divider />
                        {stationData.order.length > 0 ? (
                            stationData.order.map((car) => (
                                <Card key={car.id} size="small" className="nested-card">
                                    Car ID: {car.id} | Time Spent: {car.timeSpent.toFixed(2)}s
                                </Card>
                            ))
                        ) : (
                            <p className="card-description">No cars currently at the order station</p>
                        )}
                    </Card>

                    {/* Payment Station Card */}
                    <Card className="dashboard-card">
                        <Statistic
                            title="Cars at Payment Station"
                            value={stationData.payment.length}
                            prefix={<ClockCircleOutlined />}
                        />
                        <Divider />
                        {stationData.payment.length > 0 ? (
                            stationData.payment.map((car) => (
                                <Card key={car.id} size="small" className="nested-card">
                                    Car ID: {car.id} | Time Spent: {car.timeSpent.toFixed(2)}s
                                </Card>
                            ))
                        ) : (
                            <p className="card-description">No cars currently at the payment station</p>
                        )}
                    </Card>

                    {/* Pickup Station Card */}
                    <Card className="dashboard-card">
                        <Statistic
                            title="Cars at Pickup Station"
                            value={stationData.pickup.length}
                            prefix={<ClockCircleOutlined />}
                        />
                        <Divider />
                        {stationData.pickup.length > 0 ? (
                            stationData.pickup.map((car) => (
                                <Card key={car.id} size="small" className="nested-card">
                                    Car ID: {car.id} | Time Spent: {car.timeSpent.toFixed(2)}s
                                </Card>
                            ))
                        ) : (
                            <p className="card-description">No cars currently at the pickup station</p>
                        )}
                    </Card>
                </div>

                {/* Insights Table */}
                <div style={{ marginTop: '30px' }}>
                    <h3>Operational Insights and Suggested Actions</h3>
                    <Table
                        dataSource={insights}
                        columns={insightsColumns}
                        rowKey="car_id"
                    />
                </div>
            </Content>
        </Layout>
    );
};

export default OperatorDashboard;
